import React, { ReactElement } from 'react';
import { MdClear } from 'react-icons/md';

interface Props {
  onClick: () => void;
}

export default function CloseModalButton({ onClick }: Props): ReactElement {
  return (
    <button
      type="button"
      aria-label="Close modal"
      className="absolute z-50 top-5 right-5 p-2 border border-telesoft bg-white text-black hover:bg-telesoft hover:border-telesoft transition-all duration-300"
      onClick={onClick}
    >
      {' '}
      <MdClear size="24" />{' '}
    </button>
  );
}
