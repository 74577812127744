import React from 'react';
import { getToken } from '@payload/utils/get-token';

export interface State {
  isAuthorized: boolean;
  displaySidebar: boolean;
  displayFilter: boolean;
  displayModal: boolean;
  displaySearch: boolean;
  category: string | null;
  technology: string | null;
  modalView: string;
  modalData: any;
  drawerView: string | null;
  toastText: string;
}

const initialState = {
  isAuthorized: getToken() ? true : false,
  displaySidebar: false,
  displayFilter: false,
  displayModal: false,
  displaySearch: false,
  category: null,
  technology: null,
  modalView: 'LOGIN_VIEW',
  drawerView: null,
  modalData: null,
  toastText: '',
};

type Action =
  | {
      type: 'SET_AUTHORIZED';
    }
  | {
      type: 'SET_UNAUTHORIZED';
    }
  | {
      type: 'SET_CATEGORY';
      category: string;
    }
  | {
      type: 'SET_TECHNOLOGY';
      technology: string;
    }
  | {
      type: 'OPEN_SIDEBAR';
    }
  | {
      type: 'CLOSE_SIDEBAR';
    }
  | {
      type: 'OPEN_SEARCH';
    }
  | {
      type: 'CLOSE_SEARCH';
    }
  | {
      type: 'SET_TOAST_TEXT';
      text: ToastText;
    }
  | {
      type: 'OPEN_FILTER';
    }
  | {
      type: 'CLOSE_FILTER';
    }
  | {
      type: 'OPEN_MODAL';
    }
  | {
      type: 'CLOSE_MODAL';
    }
  | {
      type: 'SET_MODAL_VIEW';
      view: MODAL_VIEWS;
    }
  | {
      type: 'SET_DRAWER_VIEW';
      view: DRAWER_VIEWS;
    }
  | {
      type: 'SET_MODAL_DATA';
      data: any;
    };

type MODAL_VIEWS =
  | 'NETWORK_VIEW'
  | 'DOWNLOAD_VIEW'
  | 'DEMO_VIEW'
  | 'VIDEO_MODAL';
// type QUERY_TYPE = "TECHNOLOGY_QUERY" | "CATEGORY_QUERY";
type DRAWER_VIEWS = 'MOBILE_MENU' | 'NETWORK_MENU';
type ToastText = string;

export const UIContext = React.createContext<State | any>(initialState);

UIContext.displayName = 'UIContext';

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_AUTHORIZED': {
      return {
        ...state,
        isAuthorized: true,
      };
    }
    case 'SET_UNAUTHORIZED': {
      return {
        ...state,
        isAuthorized: false,
      };
    }
    case 'SET_CATEGORY': {
      return {
        ...state,
        category: action.category,
      };
    }
    case 'SET_TECHNOLOGY': {
      return {
        ...state,
        technology: action.technology,
      };
    }

    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      };
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
        drawerView: null,
      };
    }
    case 'OPEN_SEARCH': {
      return {
        ...state,
        displaySearch: true,
      };
    }
    case 'CLOSE_SEARCH': {
      return {
        ...state,
        displaySearch: false,
      };
    }
    case 'OPEN_FILTER': {
      return {
        ...state,
        displayFilter: true,
      };
    }
    case 'CLOSE_FILTER': {
      return {
        ...state,
        displayFilter: false,
      };
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
        // modalData: null,
      };
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      };
    }
    case 'SET_DRAWER_VIEW': {
      return {
        ...state,
        drawerView: action.view,
      };
    }
    case 'SET_MODAL_DATA': {
      return {
        ...state,
        modalData: action.data,
      };
    }
    case 'SET_TOAST_TEXT': {
      return {
        ...state,
        toastText: action.text,
      };
    }
  }
}

export function UIProvider(props: any) {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const authorize = () => dispatch({ type: 'SET_AUTHORIZED' });
  const unauthorize = () => dispatch({ type: 'SET_UNAUTHORIZED' });
  const openSidebar = () => dispatch({ type: 'OPEN_SIDEBAR' });
  const closeSidebar = () => dispatch({ type: 'CLOSE_SIDEBAR' });
  const toggleSidebar = () =>
    state.displaySidebar
      ? dispatch({ type: 'CLOSE_SIDEBAR' })
      : dispatch({ type: 'OPEN_SIDEBAR' });

  const openFilter = () => dispatch({ type: 'OPEN_FILTER' });
  const closeFilter = () => dispatch({ type: 'CLOSE_FILTER' });

  const openModal = () => dispatch({ type: 'OPEN_MODAL' });
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });
  const openSearch = () => dispatch({ type: 'OPEN_SEARCH' });
  const closeSearch = () => dispatch({ type: 'CLOSE_SEARCH' });

  const setModalView = (view: MODAL_VIEWS) => {
    dispatch({ type: 'SET_MODAL_VIEW', view });
  };
  function setDrawerView(view: DRAWER_VIEWS) {
    return dispatch({ type: 'SET_DRAWER_VIEW', view });
  }

  const setModalData = (data: any) => {
    dispatch({ type: 'SET_MODAL_DATA', data });
  };

  const setCategoryFilter = (category: string) => {
    dispatch({ type: 'SET_CATEGORY', category });
  };
  const setTechnologyFilter = (technology: string) => {
    dispatch({ type: 'SET_TECHNOLOGY', technology });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      authorize,
      unauthorize,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      openFilter,
      closeFilter,
      openModal,
      closeModal,
      openSearch,
      closeSearch,
      setModalView,
      setDrawerView,
      setModalData,
      setCategoryFilter,
      setTechnologyFilter,
    }),
    [state]
  );

  return <UIContext.Provider value={value} {...props} />;
}

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export function ManagedUIContext({ children }: { children: React.ReactNode }) {
  return <UIProvider>{children}</UIProvider>;
}
