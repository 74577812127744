export const CMS_NAME = 'Telesoft Cloud';
export const HOST_URL = process.env.NEXT_PUBLIC_HOST_URL;
export const HOME_OG_IMAGE_URL = '/assets/images/og-image.png';
export const RECAPTACHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
export const RECAPTACHA_SECRET_KEY = process.env.RECAPTCHA_SECRET_KEY;
export const ADMIN_PASSWORD = process.env.ADMIN_PASSWORD;
export const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;
export const ASSET_URL = process.env.NEXT_PUBLIC_ASSET_URL;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const RESOURCE_URL = process.env.NEXT_PUBLIC_RESOURCE_URL;
export const POSTHOG_ID = process.env.NEXT_PUBLIC_POSTHOG_ID;
export const COMPANY_SIZE_OPTIONS = [
  {
    label: 'Please Select...',
    value: null,
  },
  {
    label: 'Small (< 50 employees)',
    value: 'small',
  },
  {
    label: 'Medium (< 250 employees)',
    value: 'medium',
  },
  {
    label: 'Large (> 250 employees)',
    value: 'large',
  },
];

export const JOB_ROLE_OPTIONS = [
  {
    label: 'Please Select...',
    value: null,
  },
  {
    label: 'Security Analyst',
    value: 'Security Analyst',
  },
  {
    label: 'Security Engineer',
    value: 'Security Engineer',
  },
  {
    label: 'IT Security Manager / Director',
    value: 'IT Security Manager / Director',
  },
  {
    label: 'CISO/CIO/CTO/CSO/CEO',
    value: 'CISO/CIO/CTO/CSO/CEO',
  },
  {
    label: 'Consultant',
    value: 'Consultant',
  },
  {
    label: 'Security Architect',
    value: 'Security Architect',
  },
  {
    label: 'Technical Specialist',
    value: 'Technical Specialist',
  },
  {
    label: 'Network Systems Administrator',
    value: 'Network Systems Administrator',
  },
  {
    label: 'IT Security/ Network VP',
    value: 'IT Security/ Network VP',
  },
  {
    label: 'Security Researcher',
    value: 'Security Researcher',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const REGION_OPTIONS = [
  {
    label: 'Please Select...',
    value: null,
  },
  {
    label: 'North America',
    value: 'north-america',
  },
  {
    label: 'UK',
    value: 'uk',
  },
  {
    label: 'Europe',
    value: 'europe',
  },
  {
    label: 'Middle East',
    value: 'middle-east',
  },
  {
    label: 'Asia',
    value: 'asia',
  },
  {
    label: 'Australia / New Zealand',
    value: 'australia-new-zealand',
  },
  {
    label: 'Latin America',
    value: 'latin-america',
  },
  {
    label: 'Africa',
    value: 'africa',
  },
];
export const PRODUCT_OPTIONS = [
  {
    label: 'Please Select...',
    value: null,
  },
  {
    label: 'CERNE',
    value: 'cerne',
  },
  {
    label: 'FlowProbe',
    value: 'flowprobe',
  },
  {
    label: 'FlowProbe',
    value: 'flowprobe',
  },
  {
    label: 'FlowStash',
    value: 'flowstash',
  },
  {
    label: 'TDAC',
    value: 'tdac',
  },
  {
    label: 'TDAC 100',
    value: 'tdac-100',
  },
  {
    label: 'Triton NGT',
    value: 'triton-ngt',
  },
  {
    label: 'ARNE IVR',
    value: 'arne-ivr',
  },
  {
    label: 'vARNE IVR',
    value: 'varne-ivr',
  },
  {
    label: 'MPAC 3240/45',
    value: 'mpac-3240-45',
  },
  {
    label: 'MPAC 3250',
    value: 'mpac-3250',
  },
  {
    label: 'Okeford 5500',
    value: 'okeford-5500',
  },
  {
    label: 'vOkeford',
    value: 'v-okeford',
  },
  {
    label: 'TDAPI Migration Services',
    value: 'tdapi-migration-services',
  },
  {
    label: 'TDAPI SIGTRAN',
    value: 'tdapi-sigtran',
  },
  {
    label: 'Not listed here',
    value: 'not-listed',
  },
];
export const SEVERITY_OPTIONS = [
  {
    label: 'Low - Issue or question with limited business impact',
    value: 'low',
  },
  {
    label: 'Critical - Major malfunction affecting business and multiple users',
    value: 'critical',
  },
  {
    label:
      'High - Serious degradation of software / hardware performance or functionality',
    value: 'high',
  },
  {
    label:
      'Medium - Software / hardware issue with a moderate impact on business',
    value: 'medium',
  },
];
export const ATTRIBUTION_OPTIONS = [
  {
    label: 'Please Select...',
    value: null,
  },
  {
    label: 'Advertisement',
    value: 'advertisement',
  },
  {
    label: 'Social Media',
    value: 'social-media',
  },
  {
    label: 'Telesoft Employee',
    value: 'telesoft-employee',
  },
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Current Telesoft Customer',
    value: 'current-customer',
  },
  {
    label: 'Friend / Colleague',
    value: 'friend-colleague',
  },
  {
    label: 'News Article',
    value: 'news-article',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const OPT_IN_PREFERENCES = [
  {
    label: 'Phone & Email',
    value: 'phone-and-email',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
];

export const DUMMY_PRODUCT_OPTIONS = [
  {
    id: '1',
    title: 'Ingest',
    href: '/solutions/',
    description:
      'Telemetry is collected from across your networks, endpoints, and cloud environments, analysed using the latest machine learning and behavioural detection engines, then enriched with the latest threat intelligence.',
    image: '/assets/images/diagrams/flowprobe.svg',
  },
  {
    id: '2',
    title: 'Investigate',
    href: '/solutions',
    description:
      'Detections are correlated and then grouped together by common attributes to create ‘cases’ – providing a more complete overview of security events.',
    image: '/assets/images/diagrams/tdac-suite.svg',
  },
  {
    id: '3',
    title: 'Detect',
    href: '/solutions/',
    description:
      'Detections are correlated and then grouped together by common attributes to create ‘cases’ – providing a more complete overview of security events.',
    image: '/assets/images/diagrams/cerne.svg',
  },
  {
    id: '4',
    title: 'Retain',
    href: '/solutions/',
    description:
      'Detections are correlated and then grouped together by common attributes to create ‘cases’ – providing a more complete overview of security events.',
    image: '/assets/images/diagrams/datalake.svg',
  },
];

export const GOV_SERVICES_OPTIONS = [
  {
    id: 1,
    title: ' What is the Telecoms Security Bill?',
    description: '',
  },
];

export let nullGetStaticProps = {
  props: {
    page: null,
    isPreview: false,
  },
  revalidate: 10,
};
