import { useUI } from '@contexts/ui.context';
import Modal from './modal';
import dynamic from 'next/dynamic';
const DownloadResourceForm = dynamic(
  () => import('@components/common/modal/download-resource-form')
);
const BookDemoForm = dynamic(
  () => import('@components/common/modal/book-demo-form')
);
const ManagedModal: React.FC = () => {
  const { displayModal, closeModal, modalView } = useUI();
  return (
    <Modal open={displayModal} onClose={closeModal}>
      <>
        {modalView === 'DOWNLOAD_VIEW' && (
          <>
            <DownloadResourceForm />
          </>
        )}
        {modalView === 'DEMO_VIEW' && (
          <>
            <BookDemoForm />
          </>
        )}
      </>
    </Modal>
  );
};

export default ManagedModal;
